.radio {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radio-input {
  opacity: 0;
  position: absolute;
}

.radio-control {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 7px;
  vertical-align: middle;
  background-color: inherit;
  color: rgba(105, 228, 255, 1);
  border: 2px solid rgba(171, 173, 173, 1);
  border-radius: 24px;
}

.radio-input:checked + .radio-control:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  background-color: rgba(105, 228, 255, 1);
  border-radius: 12px;
  animation: radioFadeIn 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes radioFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.radio-input:checked + .radio-control {
  border-color: rgba(105, 228, 255, 1);
}

.radio-control {
  transform: scale(0.75);
}

.radio-label {
  font-size: 14px;
  font-weight: 400;
  color: white;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
