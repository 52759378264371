.note-modal-header {
  display: flex;
  align-items: center;
  gap: 8px;
  width: inherit;
}

.backIcon {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  cursor: pointer;

  &:hover {
    border-radius: 5px;
    border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
    background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  }
}

.note-modal-header__title {
  color: var(--whtie, #fff);
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* header 공통 */
.header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.header-mini {
  padding: 6px;
  height: 42px;
}

.header-expand {
  box-sizing: border-box;
  height: 50px;
  padding: 10px 20px;
}

/* Task, Event Toggle */
.toggle_container {
  display: flex;
  align-items: center;
  position: relative;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 107px;
  padding: 3px;
  user-select: none;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.4);
  transition: 0.4s;
  z-index: 10;

  /* body */
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  gap: 3px;
  transition: 0.8s;
}

.slider:before {
  display: flex;

  justify-content: center;
  align-items: center;

  position: absolute;
  content: "";
  height: 24px;
  width: 30px;
  padding: 0px 8px;
  left: 3px;
  bottom: 3px;
  background: var(--task-task-w-20, #505151);

  transition: 0.4s;
  border-radius: 5px;
  color: white;
  text-align: center;
}

input:checked + .slider {
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.4);
}

input:checked + .slider_text_Task {
  color: white;
}

input:checked + .slider:before {
  width: 36px;
  transform: translateX(49px);
}

input:disabled + .slider {
  cursor: default;
}

.blockType_tooltip {
  display: inline-flex;
  padding: 2px 5px;
  flex-direction: column;
  align-items: center;

  border-radius: 3px;
  background: var(--line-btn, #545959);
  z-index: 1000;

  position: absolute;
  pointer-events: none;
}

.blockType_tooltip span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;

  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-align: center;
  text-overflow: ellipsis;

  font-family: "Pretendard Variable";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.11px;
}

.slider_text_Task {
  position: absolute;

  text-align: center;
  top: 50%;
  left: 26px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
  font-weight: bold;

  color: var(--project-default-grey, #abadad);
  text-align: center;

  /* body */
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  transition: 0.8s;
}

.slider_text_Event {
  position: absolute;
  text-align: center;
  top: 50%;
  right: -7px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
  font-weight: bold;

  color: var(--project-default-grey, #abadad);
  text-align: center;

  /* body */
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  transition: 0.8s;
}

.slider_text_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected_text {
  color: white;
}

/* 헤더 우측 메뉴*/
.header-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  line-height: 40px;
  box-sizing: border-box;
}

.visibilityArea {
  position: relative;
}

.visibilityIcon {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  box-sizing: border-box;
  display: flex;
  width: 24px;
  height: 24px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  fill: #fff;
}

.visibilityIcon:hover {
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-16, rgba(255, 255, 255, 0.16));
}

.visibilityIcon:hover .iconDescription {
  visibility: visible;
  opacity: 1;
}

.visibilityIcon.active:hover {
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-16, rgba(255, 255, 255, 0.16));
}

.visibilityIcon.active {
  border-radius: 5px;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-16, rgba(255, 255, 255, 0.16));
}

.visibilityIcon.active:hover .iconDescription {
  visibility: hidden;
  opacity: 0;
}

.iconDescription {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  color: white;
  padding: 2px 5px;
  transition:
    visibility 0.2s,
    opacity 0.2s ease-in-out;
  white-space: nowrap;
  transform: translate(-50%, 100%);
  /* bottom: -5px; */
  left: 50%;
  z-index: 100;
  overflow: hidden;
  color: var(--g100, #d9d9d9);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.11px;
  border-radius: 3px;
  background: #545959;
}

.visibilityDropdownBox {
  transition:
    visibility 0.2s,
    opacity 0.2s ease-in-out;
  white-space: nowrap;
  /* transform: translate(-50%); */
  right: 0; /* 부모 요소의 오른쪽 끝과 맞춤 */
  top: calc(100% + 5px); /* 부모 요소의 아래로 5px 내려간 위치 */
  display: flex;
  width: 130px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;

  border-radius: 6px;
  border: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
  background: var(--task-task_dropdown, #2f3131);
  z-index: 9999;
}

:root {
  --color-active-text: #69e4ff;
  --color-active-icon: #69e4ff;
}

.dropdownListItem {
  display: flex;
  flex-direction: row;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.dropdownListItem:hover {
  background: rgba(255, 255, 255, 0.08);
  visibility: visible;
}

.dropdownListItem:hover .dropdownInfoIcon {
  visibility: visible;
}

.dropdownListItem.active {
  --text-color: var(--color-active-text);
  --icon-color: var(--color-active-icon);
}

.dropdownIcon {
  width: 16px;
  height: 16px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
  flex-grow: 0;
  fill: #69e4ff;
}

.dropdownTitle {
  overflow: hidden;
  color: var(--text-color, #fff);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  flex-grow: 1;
}

.dropdownInfoIcon {
  display: flex;
  position: relative;
  visibility: hidden;
  width: 12px;
  height: 12px;
  background-image: url(../../../assets/TaskDetail/info.svg);
  background-position: center;
  background-repeat: no-repeat;
  justify-content: flex-end;
  overflow: visible;
}

.dropdownInfoIcon:hover .dropdownInfoDescription {
  visibility: visible;
}

.dropdownInfoDescription {
  visibility: hidden;
  position: absolute;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  background: #545959;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: inline-flex;
  padding: 2px 4px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  top: 100%;
}

.infoDescriptionText {
  width: fit-content;
  color: #d9d9d9;
  text-align: right;
  z-index: 1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
}

.headerMenuAccArea {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerMenuAccArea,
.headerMenuDel,
.headerMenuDuplication,
.headerMenuExpand,
.headerMenuClose,
.visilityArea {
  position: relative;
  width: 24px;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  color: white;
  background-size: 16px;
}

.disabled {
  background-image: url(../../../assets/TaskDetail/duplicate_disabled.svg);
  cursor: not-allowed;
}

.iconDescription {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.3s linear;
}

.headerMenuExpand {
  position: relative;
  background-image: url(../../../assets/TaskDetail/fullscreen.svg);
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.headerMenuExpand:hover {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.08);
}

.headerMenuExpand:hover .iconDescription {
  visibility: visible;
  opacity: 1;
}

.headerMenuClose {
  background-image: url(../../../assets/TaskDetail/close.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.headerMenuClose:hover {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.08);
}

.header-menu-acc {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: inherit;
  cursor: default;
}

.header__more--btn {
  display: flex;
  height: 24px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.header__more--btn:hover,
.header__more--btn:active {
  border-radius: 5px;
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.header__more--icon {
  fill: #abadad;
}
.modal {
  position: absolute;
  top: 35px;
  right: 0;
  display: flex;
  width: 100px;
  height: fit-content;
  background: #363a3a;
  border: 1px solid #545959;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  z-index: 9999;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.modal > * {
  height: 30px;
  width: 100%;
  animation: fadeIn 350ms cubic-bezier(0, 0.6, 0, 1) forwards;
  font-family: "Pretendard Variable";
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  box-sizing: border-box;
  padding: 0px 10px;
}

.modal > *:hover {
  background: rgba(255, 255, 255, 0.08);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.header__shrink {
  display: flex;
  height: 24px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}

.header__shrink:hover {
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
}

.header__shrink:hover .header__shrinkIcon {
  fill: #d9d9d9;
}

.header__shrinkIcon {
  fill: #abadad;
}

.header-move {
  position: absolute;
  width: 65px;
  height: 22px;
  top: calc(50% - 11px);
  left: calc(50% - 32.5px);
  border-radius: 23px;
  background: rgba(36, 38, 38, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  color: rgba(84, 89, 89, 1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 17px;
  line-height: 22px;
}

.header-move > span {
  cursor: pointer;
}

.header-move > span:hover {
  color: white;
}
