.detail__wrap {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  background: rgba(36, 38, 38, 1);
  /* backdrop-filter: blur(2px); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  align-items: center;
  justify-content: flex-start;
  opacity: 0;
  /** Ted, Joy와 논의 완료 */
  animation: wrapFadeIn 250ms ease-in-out forwards;
  overflow: hidden;
}

@keyframes wrapFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.detail__wrap-mini {
  width: 360px;
  max-height: 600px;
  /* height: 600px; */
  border-radius: 10px;
  border-radius: 8px;
  border: 1px solid var(--w-16, rgba(255, 255, 255, 0.16));
  background: var(--task-500-default, #242626);
  backdrop-filter: blur(2px);
  overflow: hidden;
}

.detail__wrap-expand {
  width: 100%;
  height: 100vh;
}

.main {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  background: rgba(36, 38, 38, 1);
  border-radius: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.main-mini {
  width: 100%;
  height: 100%;
}

.main-expand {
  width: 100%;
  height: 100%;
}

.body {
  /* box-sizing: border-box; */
  padding-left: 36px;
  margin-right: 28px;
  display: flex;
  width: max-content;
  min-width: 600px;
  max-width: 800px;
  height: 100%;

  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  color: rgba(255, 255, 255, 0.4);
  overflow-y: scroll;
  overflow-x: hidden;
}

.bodyMini {
  width: 100%;
  height: max-content;
  overflow-y: scroll;
  overflow-x: hidden;
}

.body > hr {
  width: 1px;
  height: 100%;
  border: none;
  margin: 0 15px;
  background: rgba(84, 89, 89, 1);
}

.bodyMini > hr {
  width: 1px;
  height: 100%;
  border: none;
  margin: 0 15px;
  background: rgba(84, 89, 89, 1);
}

.bodyMini::-webkit-scrollbar {
  width: 8px;
}

.showScrollbar.bodyMini::-webkit-scrollbar {
  width: 8px;
  display: block;
}

.bodyMini::-webkit-scrollbar-thumb {
  background: transparent;
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
}

.showScrollbar.bodyMini::-webkit-scrollbar-thumb {
  background: var(--w-16, rgba(255, 255, 255, 0.16));
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
}

.bodyMini::-webkit-scrollbar-thumb:hover {
  background: rgba(84, 89, 89, 1);
  border-radius: 99px;
  background-clip: padding-box;
  border: 2px solid transparent;
  width: 4px;
  height: 100px;
}

.bodyMini::-webkit-scrollbar-track {
  background: transparent;
}

.body::-webkit-scrollbar {
  width: 8px;
}

.showScrollbar.body::-webkit-scrollbar {
  width: 8px;
  display: block;
}

.body::-webkit-scrollbar-thumb {
  background: transparent;
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
}

.showScrollbar.body::-webkit-scrollbar-thumb {
  background: var(--w-16, rgba(255, 255, 255, 0.16));
  width: 2px;
  height: 100px;
  background-clip: padding-box;
  border: 3px solid transparent;
}

.body::-webkit-scrollbar-thumb:hover {
  background: rgba(84, 89, 89, 1);
  border-radius: 99px;
  background-clip: padding-box;
  border: 2px solid transparent;
  width: 4px;
  height: 100px;
}

.body::-webkit-scrollbar-track {
  background: transparent;
}

.AccModal-main {
  width: fit-content;
  height: fit-content;
  background: rgba(54, 58, 58, 1);
  border: 1px solid rgba(84, 89, 89, 1);
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px 15px;
  border-radius: 6px;
  top: 30px;
  z-index: 100;
  animation: AccModal-fade-in 250ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes AccModal-fade-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.AccModal-row {
  width: 250px;
  height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.AccModal-row > span {
  font-size: 12px;
  font-weight: 400;
  color: white;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.AccModal-img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

/* .wideNote {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.wideNote::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}

.wideNote::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0);
}

.wideNote:hover::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.wideNote::-webkit-scrollbar-track {
  background: #242626;
} */

.footer {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-top: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  height: 50px;
  width: 100%;
}

.footerExpand {
  justify-content: flex-end;
}

.setting-save--disabled {
  cursor: pointer;
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  cursor: not-allowed;
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-8_hover, rgba(255, 255, 255, 0.08));
  transition: all ease 0.3s;
  color: var(--w-24, rgba(255, 255, 255, 0.24));
  box-sizing: border-box;
  height: 100%;
  width: 70px;
  text-align: center;

  /* body */
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.setting-save {
  border: 1px solid var(--w-8_hover, rgba(255, 255, 255, 0.08));
  background: var(--w-16, rgba(255, 255, 255, 0.16));
  transition: all ease 0.3s;
  color: #fff;
  box-sizing: border-box;
  height: 100%;
  width: 70px;
  text-align: center;
  cursor: pointer;
}

.setting-save p {
  transition: all ease 0.3s;
  color: white;
}
