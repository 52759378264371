.tap__contents::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}

.tap__contents::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0);
}

.tap__contents:hover::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.tap__contents::-webkit-scrollbar-track {
  background: #363a3a;
}

.trash {
  display: block;
  position: absolute;
  width: 340px;
  height: 570px;
  left: 46px;
  bottom: 0px;
  border-radius: 6px;
  border: 1px solid #545959;
  background: #363a3a;
  overflow: hidden;
  z-index: 999;
  cursor: pointer;
}

.trash ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0;
}

.trash li {
  line-height: 50px;
}

.tap__inactive {
  width: 50%;
  height: 50px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  background: rgba(26, 28, 28, 0.4);
  cursor: pointer;
}

.tap__active {
  width: 50%;
  height: 50px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.tap__contents {
  height: 460px;
  margin-left: 10px;
  padding-right: 14px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 10px;
}

.project__delete {
  width: 16px;
  height: 16px;
}

.project__delete__img {
  width: 16px;
  height: 16px;
}

.project__title {
  flex-grow: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.project__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 10px;
}

.project__icon {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  box-shadow: 0 0 0 2px white inset;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  /* position: relative; */
}

.trash__guide {
  display: flex;
  align-items: center;
  height: 60px;
  width: fit-content;
  padding: 0px 20px;
}

.trash__guide__empty {
  display: flex;
  width: 75px;
  height: 30px;
  border-radius: 100px;
  border: 0.5px solid #fff;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.trash__guide__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  color: #abadad;
  margin-right: 8px;
  z-index: 999;
}

.trash__guide__empty__text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.12px;
  margin-left: 5px;
}

.taskList__body {
  width: 260px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.taskList__body__integration {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: rgba(26, 28, 28, 1);
  color: white;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  position: absolute;
  left: 234px;
  left: 334px;
  opacity: 1;
}

.taskList__body__icon {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  margin: 10px 10px;
}

.taskListBodyIconDone {
  background-image: url(../../../assets/Common/timeblock-check-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.taskList__body__title {
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
  height: 36px;
  line-height: 36px;
  border: none;
  background: none;
}

/* NOTE: 사용되지 않고 있는 class */
.taskBoxBody {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.taskBoxBodyIcon {
  min-width: 16px;
  min-height: 16px;
  border-radius: 3px;
  margin: 10px 10px;
}

.taskListBodyTitle {
  display: flex;
  color: white;
  font-weight: 400;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 36px;
  line-height: 36px;
  border: none;
  background: none;
  flex-grow: 1;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
}

.integration {
  min-width: 16px;
  min-height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.integration-gmail {
  background-image: url(../../../assets/Main/gmail-icon.svg);
}

.integration-jira {
  background-image: url(../../../assets/Main/jira-icon.svg);
}

.integration-slack {
  background-image: url(../../../assets/Main/slack-icon.svg);
}

.visibilityIcon {
  min-width: 16px;
  min-height: 17px;
}
